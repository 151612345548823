/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:28:25Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddFirmwareUpdate } from '../model/addFirmwareUpdate';
import { AdminFirmwareUpdateCollection } from '../model/adminFirmwareUpdateCollection';
import { Company } from '../model/company';
import { CompanyCollection } from '../model/companyCollection';
import { CompanyGroupCollection } from '../model/companyGroupCollection';
import { CompanyGroupSettingsUpdate } from '../model/companyGroupSettingsUpdate';
import { CompanyGroupToAdd } from '../model/companyGroupToAdd';
import { CompanyToAdd } from '../model/companyToAdd';
import { DeviceErrorCollection } from '../model/deviceErrorCollection';
import { FirmwareUpdateOutput } from '../model/firmwareUpdateOutput';
import { FirmwareUpdateSettingsUpdate } from '../model/firmwareUpdateSettingsUpdate';
import { IDCollection } from '../model/iDCollection';
import { LogCollection } from '../model/logCollection';
import { OilTemperatureFormulaSettings } from '../model/oilTemperatureFormulaSettings';
import { OilTemperatureFormulas } from '../model/oilTemperatureFormulas';
import { RawTemperatureOilLevelCollection } from '../model/rawTemperatureOilLevelCollection';
import { SystemStatus } from '../model/systemStatus';
import { UserCompanyAssociationInput } from '../model/userCompanyAssociationInput';
import { UserCompanyAssociationUpdate } from '../model/userCompanyAssociationUpdate';
import { UserEmailCollection } from '../model/userEmailCollection';
import { UserOverviewCollection } from '../model/userOverviewCollection';
import { UserProtectedSettings } from '../model/userProtectedSettings';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://api.dev.ifd.dynamicsynergyiot.com/admin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds a company to the system
     * 
     * @param companyToAdd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCompany(companyToAdd: CompanyToAdd, observe?: 'body', reportProgress?: boolean): Observable<Company>;
    public addCompany(companyToAdd: CompanyToAdd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Company>>;
    public addCompany(companyToAdd: CompanyToAdd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Company>>;
    public addCompany(companyToAdd: CompanyToAdd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyToAdd === null || companyToAdd === undefined) {
            throw new Error('Required parameter companyToAdd was null or undefined when calling addCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Company>(`${this.basePath}/companies`,
            companyToAdd,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a company grouping for the purpose of managing firmware update access.
     * 
     * @param companyGroupToAdd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCompanyGroup(companyGroupToAdd: CompanyGroupToAdd, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public addCompanyGroup(companyGroupToAdd: CompanyGroupToAdd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public addCompanyGroup(companyGroupToAdd: CompanyGroupToAdd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public addCompanyGroup(companyGroupToAdd: CompanyGroupToAdd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyGroupToAdd === null || companyGroupToAdd === undefined) {
            throw new Error('Required parameter companyGroupToAdd was null or undefined when calling addCompanyGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/companies/groups`,
            companyGroupToAdd,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a firmware update to the system with Presigned URL
     * Admins can add firmware updates and get a presigned URL for PDF uploads.
     * @param addFirmwareUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFirmwareUpdate(addFirmwareUpdate: AddFirmwareUpdate, observe?: 'body', reportProgress?: boolean): Observable<FirmwareUpdateOutput>;
    public addFirmwareUpdate(addFirmwareUpdate: AddFirmwareUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FirmwareUpdateOutput>>;
    public addFirmwareUpdate(addFirmwareUpdate: AddFirmwareUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FirmwareUpdateOutput>>;
    public addFirmwareUpdate(addFirmwareUpdate: AddFirmwareUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addFirmwareUpdate === null || addFirmwareUpdate === undefined) {
            throw new Error('Required parameter addFirmwareUpdate was null or undefined when calling addFirmwareUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FirmwareUpdateOutput>(`${this.basePath}/firmware/updates`,
            addFirmwareUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds an oil temperature formula to the system
     * 
     * @param oilTemperatureFormulaSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOilTemperatureFormula(oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public addOilTemperatureFormula(oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public addOilTemperatureFormula(oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public addOilTemperatureFormula(oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (oilTemperatureFormulaSettings === null || oilTemperatureFormulaSettings === undefined) {
            throw new Error('Required parameter oilTemperatureFormulaSettings was null or undefined when calling addOilTemperatureFormula.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/formulas/temperature`,
            oilTemperatureFormulaSettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds users to the system
     * 
     * @param userEmailCollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUsers(userEmailCollection: UserEmailCollection, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public addUsers(userEmailCollection: UserEmailCollection, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public addUsers(userEmailCollection: UserEmailCollection, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public addUsers(userEmailCollection: UserEmailCollection, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userEmailCollection === null || userEmailCollection === undefined) {
            throw new Error('Required parameter userEmailCollection was null or undefined when calling addUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/users`,
            userEmailCollection,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Associates a user with a company
     * 
     * @param userid User ID of User to associate with a compnay
     * @param userCompanyAssociationInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public associateUserWithCompany(userid: string, userCompanyAssociationInput: UserCompanyAssociationInput, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public associateUserWithCompany(userid: string, userCompanyAssociationInput: UserCompanyAssociationInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public associateUserWithCompany(userid: string, userCompanyAssociationInput: UserCompanyAssociationInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public associateUserWithCompany(userid: string, userCompanyAssociationInput: UserCompanyAssociationInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling associateUserWithCompany.');
        }

        if (userCompanyAssociationInput === null || userCompanyAssociationInput === undefined) {
            throw new Error('Required parameter userCompanyAssociationInput was null or undefined when calling associateUserWithCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/users/${encodeURIComponent(String(userid))}/companies`,
            userCompanyAssociationInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a company from the system
     * 
     * @param companyid Company to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompany(companyid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCompany(companyid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCompany(companyid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCompany(companyid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling deleteCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a company group
     * 
     * @param groupid Id of the company group to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyGroup(groupid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCompanyGroup(groupid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCompanyGroup(groupid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCompanyGroup(groupid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupid === null || groupid === undefined) {
            throw new Error('Required parameter groupid was null or undefined when calling deleteCompanyGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/companies/groups/${encodeURIComponent(String(groupid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an oil temperature formula. Will fail if the formula is currently referenced by any companies.
     * 
     * @param formulaid The ID of the oil temperature formula to delete.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOilTemperatureFormula(formulaid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOilTemperatureFormula(formulaid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOilTemperatureFormula(formulaid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOilTemperatureFormula(formulaid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (formulaid === null || formulaid === undefined) {
            throw new Error('Required parameter formulaid was null or undefined when calling deleteOilTemperatureFormula.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/formulas/temperature/${encodeURIComponent(String(formulaid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete users from the system
     * 
     * @param iDCollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsers(iDCollection: IDCollection, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUsers(iDCollection: IDCollection, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUsers(iDCollection: IDCollection, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUsers(iDCollection: IDCollection, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (iDCollection === null || iDCollection === undefined) {
            throw new Error('Required parameter iDCollection was null or undefined when calling deleteUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/users/delete`,
            iDCollection,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Admin Firmware Updates with Pre-signed URLs
     * Fetches firmware updates available to system administrators, including pre-signed URLs for direct PDF retrieval. This is useful for admins who need to access firmware update documentation directly.
     * @param devices Filters for firmware updates that are currently in use by one or more of the specified devices. The format is a comma-separated list of device IDs
     * @param companies Filters for firmware updates that include one or more of the specified companies on their staging whitelists. The format is a comma-separated list of company IDs.
     * @param ids A comma-separated list of firmware update IDs to search for.
     * @param active Boolean parameter indicating whether to look for only active or inactive firmware updates.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminFirmwareUpdates(devices?: string, companies?: string, ids?: string, active?: string, observe?: 'body', reportProgress?: boolean): Observable<AdminFirmwareUpdateCollection>;
    public getAdminFirmwareUpdates(devices?: string, companies?: string, ids?: string, active?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminFirmwareUpdateCollection>>;
    public getAdminFirmwareUpdates(devices?: string, companies?: string, ids?: string, active?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminFirmwareUpdateCollection>>;
    public getAdminFirmwareUpdates(devices?: string, companies?: string, ids?: string, active?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (devices !== undefined && devices !== null) {
            queryParameters = queryParameters.set('devices', <any>devices);
        }
        if (companies !== undefined && companies !== null) {
            queryParameters = queryParameters.set('companies', <any>companies);
        }
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', <any>active);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AdminFirmwareUpdateCollection>(`${this.basePath}/firmware/updates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all companies in the system
     * 
     * @param limit Max number of companies to retrieve
     * @param name A partial match search for the name of a company
     * @param offset Offset from start
     * @param companyIds The included or excluded (preceded by a minus sign) company IDs to use in the search
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanies(limit?: string, name?: string, offset?: string, companyIds?: string, observe?: 'body', reportProgress?: boolean): Observable<CompanyCollection>;
    public getCompanies(limit?: string, name?: string, offset?: string, companyIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyCollection>>;
    public getCompanies(limit?: string, name?: string, offset?: string, companyIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyCollection>>;
    public getCompanies(limit?: string, name?: string, offset?: string, companyIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (companyIds !== undefined && companyIds !== null) {
            queryParameters = queryParameters.set('company_ids', <any>companyIds);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CompanyCollection>(`${this.basePath}/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the available company groupings for controlling access to firmware updates
     * 
     * @param ids Filter for company groups with particular IDs
     * @param limit Max size
     * @param name Full text search for the name of 
     * @param offset The offset of the log entry to get for paging purposes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyGroups(ids?: string, limit?: string, name?: string, offset?: string, observe?: 'body', reportProgress?: boolean): Observable<CompanyGroupCollection>;
    public getCompanyGroups(ids?: string, limit?: string, name?: string, offset?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyGroupCollection>>;
    public getCompanyGroups(ids?: string, limit?: string, name?: string, offset?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyGroupCollection>>;
    public getCompanyGroups(ids?: string, limit?: string, name?: string, offset?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CompanyGroupCollection>(`${this.basePath}/companies/groups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get diagnostic statistics for one or more devices
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceDiagnosticStats(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getDeviceDiagnosticStats(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getDeviceDiagnosticStats(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getDeviceDiagnosticStats(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/devices/diagnostics/stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all companies in the system
     * 
     * @param paginationToken Pass the previously fetched pagination token to this parameter to get the next set of results
     * @param deviceIds The included or excluded (preceded by a minus sign) company IDs to use in the search
     * @param limit Max number of companies to retrieve
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceErrors(paginationToken?: string, deviceIds?: string, limit?: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceErrorCollection>;
    public getDeviceErrors(paginationToken?: string, deviceIds?: string, limit?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceErrorCollection>>;
    public getDeviceErrors(paginationToken?: string, deviceIds?: string, limit?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceErrorCollection>>;
    public getDeviceErrors(paginationToken?: string, deviceIds?: string, limit?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paginationToken !== undefined && paginationToken !== null) {
            queryParameters = queryParameters.set('pagination_token', <any>paginationToken);
        }
        if (deviceIds !== undefined && deviceIds !== null) {
            queryParameters = queryParameters.set('device_ids', <any>deviceIds);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DeviceErrorCollection>(`${this.basePath}/devices/errors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets available logs
     * 
     * @param limit Max size
     * @param fromTimestamp Beginning of timescale
     * @param offset The offset of the log entry to get for paging purposes
     * @param toTimestamp End of the timescale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe?: 'body', reportProgress?: boolean): Observable<LogCollection>;
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogCollection>>;
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogCollection>>;
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (fromTimestamp !== undefined && fromTimestamp !== null) {
            queryParameters = queryParameters.set('from_timestamp', <any>fromTimestamp);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (toTimestamp !== undefined && toTimestamp !== null) {
            queryParameters = queryParameters.set('to_timestamp', <any>toTimestamp);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json '
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LogCollection>(`${this.basePath}/logs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the available temperature formulas from the database
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOilTemperatureFormulas(observe?: 'body', reportProgress?: boolean): Observable<OilTemperatureFormulas>;
    public getOilTemperatureFormulas(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OilTemperatureFormulas>>;
    public getOilTemperatureFormulas(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OilTemperatureFormulas>>;
    public getOilTemperatureFormulas(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OilTemperatureFormulas>(`${this.basePath}/formulas/temperature`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets raw temperature-based oil levels
     * 
     * @param fromTimestamp The unix timestamp from which to start getting results.
     * @param deviceIds The device IDs to get levels for.
     * @param toTimestamp The unix timestamp before which to get results.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRawTemperatureOilLevels(fromTimestamp?: string, deviceIds?: string, toTimestamp?: string, observe?: 'body', reportProgress?: boolean): Observable<RawTemperatureOilLevelCollection>;
    public getRawTemperatureOilLevels(fromTimestamp?: string, deviceIds?: string, toTimestamp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RawTemperatureOilLevelCollection>>;
    public getRawTemperatureOilLevels(fromTimestamp?: string, deviceIds?: string, toTimestamp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RawTemperatureOilLevelCollection>>;
    public getRawTemperatureOilLevels(fromTimestamp?: string, deviceIds?: string, toTimestamp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromTimestamp !== undefined && fromTimestamp !== null) {
            queryParameters = queryParameters.set('from_timestamp', <any>fromTimestamp);
        }
        if (deviceIds !== undefined && deviceIds !== null) {
            queryParameters = queryParameters.set('device_ids', <any>deviceIds);
        }
        if (toTimestamp !== undefined && toTimestamp !== null) {
            queryParameters = queryParameters.set('to_timestamp', <any>toTimestamp);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RawTemperatureOilLevelCollection>(`${this.basePath}/devices/measurements/oil/level`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets status information about the OpsAware system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemStatus(observe?: 'body', reportProgress?: boolean): Observable<SystemStatus>;
    public getSystemStatus(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SystemStatus>>;
    public getSystemStatus(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SystemStatus>>;
    public getSystemStatus(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SystemStatus>(`${this.basePath}/system`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of users visible to the authenticated user
     * 
     * @param isKey Filter for users which are a key contact in some company
     * @param username A prefix-search for the user&#39;s email/username
     * @param limit Max number of notifications to receive
     * @param offset Offset from notification start
     * @param isSysadmin Filter on the system admin property
     * @param ids The user IDs to get specifically
     * @param companyIds The IDs of the companies to filter the user list by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(isKey?: string, username?: string, limit?: string, offset?: string, isSysadmin?: string, ids?: string, companyIds?: string, observe?: 'body', reportProgress?: boolean): Observable<UserOverviewCollection>;
    public getUsers(isKey?: string, username?: string, limit?: string, offset?: string, isSysadmin?: string, ids?: string, companyIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserOverviewCollection>>;
    public getUsers(isKey?: string, username?: string, limit?: string, offset?: string, isSysadmin?: string, ids?: string, companyIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserOverviewCollection>>;
    public getUsers(isKey?: string, username?: string, limit?: string, offset?: string, isSysadmin?: string, ids?: string, companyIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isKey !== undefined && isKey !== null) {
            queryParameters = queryParameters.set('is_key', <any>isKey);
        }
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (isSysadmin !== undefined && isSysadmin !== null) {
            queryParameters = queryParameters.set('is_sysadmin', <any>isSysadmin);
        }
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }
        if (companyIds !== undefined && companyIds !== null) {
            queryParameters = queryParameters.set('company_ids', <any>companyIds);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserOverviewCollection>(`${this.basePath}/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes a user from a company
     * 
     * @param userid The user ID to remove from the company
     * @param companyid The ID of the company to remove the user from
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeUserFromCompany(userid: string, companyid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeUserFromCompany(userid: string, companyid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeUserFromCompany(userid: string, companyid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeUserFromCompany(userid: string, companyid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling removeUserFromCompany.');
        }

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling removeUserFromCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/users/${encodeURIComponent(String(userid))}/companies/${encodeURIComponent(String(companyid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the settings of a company group.
     * 
     * @param groupid Id of the company group to update
     * @param companyGroupSettingsUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyGroupSettings(groupid: string, companyGroupSettingsUpdate: CompanyGroupSettingsUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCompanyGroupSettings(groupid: string, companyGroupSettingsUpdate: CompanyGroupSettingsUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCompanyGroupSettings(groupid: string, companyGroupSettingsUpdate: CompanyGroupSettingsUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCompanyGroupSettings(groupid: string, companyGroupSettingsUpdate: CompanyGroupSettingsUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupid === null || groupid === undefined) {
            throw new Error('Required parameter groupid was null or undefined when calling updateCompanyGroupSettings.');
        }

        if (companyGroupSettingsUpdate === null || companyGroupSettingsUpdate === undefined) {
            throw new Error('Required parameter companyGroupSettingsUpdate was null or undefined when calling updateCompanyGroupSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/companies/groups/${encodeURIComponent(String(groupid))}`,
            companyGroupSettingsUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the settings of a firmware update image
     * 
     * @param imageid The ID of the firmware update to update the settings for.
     * @param firmwareUpdateSettingsUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFirmwareUpdateSettings(imageid: string, firmwareUpdateSettingsUpdate: FirmwareUpdateSettingsUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateFirmwareUpdateSettings(imageid: string, firmwareUpdateSettingsUpdate: FirmwareUpdateSettingsUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateFirmwareUpdateSettings(imageid: string, firmwareUpdateSettingsUpdate: FirmwareUpdateSettingsUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateFirmwareUpdateSettings(imageid: string, firmwareUpdateSettingsUpdate: FirmwareUpdateSettingsUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imageid === null || imageid === undefined) {
            throw new Error('Required parameter imageid was null or undefined when calling updateFirmwareUpdateSettings.');
        }

        if (firmwareUpdateSettingsUpdate === null || firmwareUpdateSettingsUpdate === undefined) {
            throw new Error('Required parameter firmwareUpdateSettingsUpdate was null or undefined when calling updateFirmwareUpdateSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/firmware/updates/${encodeURIComponent(String(imageid))}`,
            firmwareUpdateSettingsUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an oil temperature formula
     * 
     * @param formulaid The ID of the oil temperature formula to update the settings for.
     * @param oilTemperatureFormulaSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOilTemperatureFormula(formulaid: string, oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateOilTemperatureFormula(formulaid: string, oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateOilTemperatureFormula(formulaid: string, oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateOilTemperatureFormula(formulaid: string, oilTemperatureFormulaSettings: OilTemperatureFormulaSettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (formulaid === null || formulaid === undefined) {
            throw new Error('Required parameter formulaid was null or undefined when calling updateOilTemperatureFormula.');
        }

        if (oilTemperatureFormulaSettings === null || oilTemperatureFormulaSettings === undefined) {
            throw new Error('Required parameter oilTemperatureFormulaSettings was null or undefined when calling updateOilTemperatureFormula.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/formulas/temperature/${encodeURIComponent(String(formulaid))}`,
            oilTemperatureFormulaSettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a user&#39;s association with a company
     * 
     * @param userid The ID of the user to change the company association for
     * @param companyid The ID of the company to change the user association for
     * @param userCompanyAssociationUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling updateUserCompanyAssociation.');
        }

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling updateUserCompanyAssociation.');
        }

        if (userCompanyAssociationUpdate === null || userCompanyAssociationUpdate === undefined) {
            throw new Error('Required parameter userCompanyAssociationUpdate was null or undefined when calling updateUserCompanyAssociation.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/users/${encodeURIComponent(String(userid))}/companies/${encodeURIComponent(String(companyid))}`,
            userCompanyAssociationUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates protected user settings
     * 
     * @param userid User ID of User to update
     * @param userProtectedSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserProtectedSettings(userid: string, userProtectedSettings: UserProtectedSettings, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUserProtectedSettings(userid: string, userProtectedSettings: UserProtectedSettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUserProtectedSettings(userid: string, userProtectedSettings: UserProtectedSettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUserProtectedSettings(userid: string, userProtectedSettings: UserProtectedSettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling updateUserProtectedSettings.');
        }

        if (userProtectedSettings === null || userProtectedSettings === undefined) {
            throw new Error('Required parameter userProtectedSettings was null or undefined when calling updateUserProtectedSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/users/${encodeURIComponent(String(userid))}/settings`,
            userProtectedSettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
