/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:28:25Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * View-able log entry
 */
export interface LogEntry { 
    /**
     * the contents of the log. For example, if the log message is recording a change in settings this might be the new settings
     */
    content: any;
    /**
     * the time at which the event occurred as a unix timestamp
     */
    eventTimeUTC: number;
    /**
     * the log message which should give the user a general idea why the log was made
     */
    message: string;
    /**
     * The type of the log entry encoded as an integer
     */
    type: number;
}
