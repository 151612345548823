/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PeripheralCounts } from './peripheralCounts';


/**
 * describes statistics about the state of all the devices
 */
export interface DevicesStats { 
    distribution: PeripheralCounts;
    /**
     * The number of devices with active extreme overload notifications.
     */
    extremeOverloadCount?: number;
    /**
     * The number of devices experiencing a low battery notification
     */
    lowBatteryCount: number;
    /**
     * The number of devices with an active low signal notification.
     */
    lowSignalCount: number;
    /**
     * The number of device's with missing reports.
     */
    missingReportCount: number;
    /**
     * The number of devices that are no currently being powered by mains power
     */
    noSecondaryPower: number;
    /**
     * The number of devices with active overloads notifications.
     */
    overloadCount?: number;
    /**
     * The total number of devices in all the companies the user is registered to
     */
    totalDevices: number;
}
