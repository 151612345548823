/**
 * dev-ops-aware-help-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:31:57Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The URL resulting from a documentation asset upload
 */
export interface DocumentationAssetUploadURL { 
    /**
     * The pre-signed URL that the file can be uploaded to
     */
    url: string;
}
