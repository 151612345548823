/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:28:25Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompanyGroup } from './companyGroup';


/**
 * A collection of company groups
 */
export interface CompanyGroupCollection { 
    /**
     * An array of the company groups resulting from the search.
     */
    companyGroups: Array<CompanyGroup>;
}
