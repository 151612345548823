/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareDescription } from './firmwareDescription';


/**
 * Describes an available firmware update
 */
export interface FirmwareUpdate { 
    /**
     * Whether or not the firmware image is active and will be shown as available to users.
     */
    active: boolean;
    /**
     * The base address of the firmware image
     */
    baseAddress: number;
    /**
     * The reason the firmware image was deactivated.
     */
    deactivationReason?: string;
    firmwareDescription: FirmwareDescription;
    /**
     * The format of the firmware image
     */
    format: number;
    /**
     * The firmware image's ID
     */
    id: number;
    /**
     * The length of the firmware image in bytes
     */
    lengthInBytes: number;
    /**
     * The timestamp of when the firmware image was added.
     */
    uploadTimestamp: number;
}
