/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Describes the state of notifications for a device
 */
export interface DeviceNotificationState { 
    disconnectedPeripheral?: number;
    extremeOverload?: number;
    lowBattery?: number;
    lowImpedanceFault?: number;
    lowSignal?: number;
    missingReport?: number;
    /**
     * Deprecated this is superseded by \"noMainsPower\" which better matches terminology in the rest of the API
     */
    noMains?: number;
    noMainsPower?: number;
    overload?: number;
    restoredMainsPower?: number;
}
