/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:28:25Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A collection of versions in the system
 */
export interface SystemStatusVersions { 
    /**
     * The version of the business to business API.
     */
    b2bAPIVersion: string;
    /**
     * The version of the backend source
     */
    backendVersion: string;
    /**
     * The MQTT protocol versions that are currently supported by the backend
     */
    supportedMQTTProtocolVersions: Array<number>;
}
