/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * An overview of a device group
 */
export interface DeviceGroupOverview { 
    /**
     * A unix timestamp taken when the group was created
     */
    creationDate: number;
    /**
     * A description of the group
     */
    description: string;
    /**
     * The ID of the group
     */
    id: number;
    /**
     * The name of the group
     */
    name: string;
}
