/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * describes an update operation to the device's descriptive fields
 */
export interface DeviceDescriptionUpdate { 
    /**
     * Updates the description of the device's location. If left undefined no update to the value will occur.
     */
    locationDescription?: string;
    /**
     * Updates the transformer type associated with the device. If left undefined no update to the value will occur.
     */
    transformerType?: number;
}
