/**
 * dev-ops-aware-help-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:31:57Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A description of a published documentation version
 */
export interface DocumentationVersion { 
    /**
     * A description of what the changes to the document format are
     */
    description?: string;
    /**
     * The ID of the version
     */
    id: number;
    /**
     * The name of the version
     */
    name: string;
    /**
     * The user ID of the user who published the version
     */
    publishedBy: number;
    /**
     * The unix timestamp of when the documentation was published
     */
    publishedOn: number;
}
