/**
 * dev-ops-aware-help-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:31:57Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Input for creation or updating of a documentation page
 */
export interface DocumentationPageCreationInput { 
    /**
     * The body of the document. It is expected that the document will be in Markdown, though that is not explicitly checked for or required.
     */
    document: string;
}
