/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareUpdateV2 } from './firmwareUpdateV2';


/**
 * Collection of firmware update images
 */
export interface FirmwareUpdateCollectionV2 { 
    /**
     * An array of application images
     */
    applicationImages: Array<FirmwareUpdateV2>;
}
