/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:23:40Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { APIKeyCollection } from '../model/aPIKeyCollection';
import { APIKeySettings } from '../model/aPIKeySettings';
import { AcknowledgeNotificationsInput } from '../model/acknowledgeNotificationsInput';
import { CompanyCollection } from '../model/companyCollection';
import { CompanyUserInviteCollection } from '../model/companyUserInviteCollection';
import { ID } from '../model/iD';
import { NotificationCollection } from '../model/notificationCollection';
import { SeeNotificationsInput } from '../model/seeNotificationsInput';
import { SubscriptionCollection } from '../model/subscriptionCollection';
import { SubscriptionRequest } from '../model/subscriptionRequest';
import { SubscriptionSettings } from '../model/subscriptionSettings';
import { UserCompanyAssociationUpdate } from '../model/userCompanyAssociationUpdate';
import { UserOverview } from '../model/userOverview';
import { UserOverviewCollection } from '../model/userOverviewCollection';
import { UserUpdate } from '../model/userUpdate';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://api.dev.ifd.dynamicsynergyiot.com/users';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Marks notifications as \&quot;acknowledged\&quot; by the authenticated user
     * 
     * @param acknowledgeNotificationsInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acknowledgeNotifications(acknowledgeNotificationsInput: AcknowledgeNotificationsInput, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public acknowledgeNotifications(acknowledgeNotificationsInput: AcknowledgeNotificationsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public acknowledgeNotifications(acknowledgeNotificationsInput: AcknowledgeNotificationsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public acknowledgeNotifications(acknowledgeNotificationsInput: AcknowledgeNotificationsInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (acknowledgeNotificationsInput === null || acknowledgeNotificationsInput === undefined) {
            throw new Error('Required parameter acknowledgeNotificationsInput was null or undefined when calling acknowledgeNotifications.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/notifications/acknowledge`,
            acknowledgeNotificationsInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a B2B API key to the specified company
     * 
     * @param companyid The company to add the API key to
     * @param aPIKeySettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAPIKey(companyid: string, aPIKeySettings: APIKeySettings, observe?: 'body', reportProgress?: boolean): Observable<ID>;
    public addAPIKey(companyid: string, aPIKeySettings: APIKeySettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ID>>;
    public addAPIKey(companyid: string, aPIKeySettings: APIKeySettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ID>>;
    public addAPIKey(companyid: string, aPIKeySettings: APIKeySettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling addAPIKey.');
        }

        if (aPIKeySettings === null || aPIKeySettings === undefined) {
            throw new Error('Required parameter aPIKeySettings was null or undefined when calling addAPIKey.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ID>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/keys`,
            aPIKeySettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a subscription to the authenticated user
     * 
     * @param subscriptionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSubscription(subscriptionRequest: SubscriptionRequest, observe?: 'body', reportProgress?: boolean): Observable<ID>;
    public addSubscription(subscriptionRequest: SubscriptionRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ID>>;
    public addSubscription(subscriptionRequest: SubscriptionRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ID>>;
    public addSubscription(subscriptionRequest: SubscriptionRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subscriptionRequest === null || subscriptionRequest === undefined) {
            throw new Error('Required parameter subscriptionRequest was null or undefined when calling addSubscription.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ID>(`${this.basePath}/subscriptions`,
            subscriptionRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a B2B API key from a company
     * 
     * @param keyid The ID number of the key to remove
     * @param companyid The company to remove the API key from
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAPIKey(keyid: string, companyid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAPIKey(keyid: string, companyid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAPIKey(keyid: string, companyid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAPIKey(keyid: string, companyid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyid === null || keyid === undefined) {
            throw new Error('Required parameter keyid was null or undefined when calling deleteAPIKey.');
        }

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling deleteAPIKey.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/keys/${encodeURIComponent(String(keyid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of the company&#39;s keys for the B2B API
     * 
     * @param companyid The ID of the company to get the keys for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAPIKeys(companyid: string, observe?: 'body', reportProgress?: boolean): Observable<APIKeyCollection>;
    public getAPIKeys(companyid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<APIKeyCollection>>;
    public getAPIKeys(companyid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<APIKeyCollection>>;
    public getAPIKeys(companyid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling getAPIKeys.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<APIKeyCollection>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/keys`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets an authenticated user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAuthenticatedUser(observe?: 'body', reportProgress?: boolean): Observable<UserOverview>;
    public getAuthenticatedUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserOverview>>;
    public getAuthenticatedUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserOverview>>;
    public getAuthenticatedUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserOverview>(`${this.basePath}/users/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of the companies a user is part of
     * 
     * @param offset Offset from notification start
     * @param ids The included or excluded (preceded by a minus sign) company IDs to include in the search
     * @param limit Max number of notifications to receive
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanies(offset?: string, ids?: string, limit?: string, observe?: 'body', reportProgress?: boolean): Observable<CompanyCollection>;
    public getCompanies(offset?: string, ids?: string, limit?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyCollection>>;
    public getCompanies(offset?: string, ids?: string, limit?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyCollection>>;
    public getCompanies(offset?: string, ids?: string, limit?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CompanyCollection>(`${this.basePath}/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets notifications
     * 
     * @param seen Search only for devices which have or haven&#39;t been seen by the calling user.
     * @param limit Max number of notifications to receive
     * @param acknowledged Search only for devices which have or haven&#39;t been acknowledged
     * @param offset Offset from notification start
     * @param deviceIds Comma seperated list of device IDs to search for notifications for
     * @param types Comma separated list of notification types to filter for
     * @param active Search only for devices with a particular activation state (true or false)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotifications(seen?: string, limit?: string, acknowledged?: string, offset?: string, deviceIds?: string, types?: string, active?: string, observe?: 'body', reportProgress?: boolean): Observable<NotificationCollection>;
    public getNotifications(seen?: string, limit?: string, acknowledged?: string, offset?: string, deviceIds?: string, types?: string, active?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationCollection>>;
    public getNotifications(seen?: string, limit?: string, acknowledged?: string, offset?: string, deviceIds?: string, types?: string, active?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationCollection>>;
    public getNotifications(seen?: string, limit?: string, acknowledged?: string, offset?: string, deviceIds?: string, types?: string, active?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (seen !== undefined && seen !== null) {
            queryParameters = queryParameters.set('seen', <any>seen);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (acknowledged !== undefined && acknowledged !== null) {
            queryParameters = queryParameters.set('acknowledged', <any>acknowledged);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (deviceIds !== undefined && deviceIds !== null) {
            queryParameters = queryParameters.set('device_ids', <any>deviceIds);
        }
        if (types !== undefined && types !== null) {
            queryParameters = queryParameters.set('types', <any>types);
        }
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', <any>active);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NotificationCollection>(`${this.basePath}/notifications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the authenticated user&#39;s subscriptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubscriptions(observe?: 'body', reportProgress?: boolean): Observable<SubscriptionCollection>;
    public getSubscriptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubscriptionCollection>>;
    public getSubscriptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubscriptionCollection>>;
    public getSubscriptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SubscriptionCollection>(`${this.basePath}/subscriptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of users visible to the authenticated user
     * 
     * @param isKey Filters for only users which are key contacts
     * @param ids The user IDs to get specifically
     * @param limit Max number of notifications to receive
     * @param offset Offset from notification start
     * @param companyIds The IDs of the companies to filter the user list by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(isKey?: string, ids?: string, limit?: string, offset?: string, companyIds?: string, observe?: 'body', reportProgress?: boolean): Observable<UserOverviewCollection>;
    public getUsers(isKey?: string, ids?: string, limit?: string, offset?: string, companyIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserOverviewCollection>>;
    public getUsers(isKey?: string, ids?: string, limit?: string, offset?: string, companyIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserOverviewCollection>>;
    public getUsers(isKey?: string, ids?: string, limit?: string, offset?: string, companyIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isKey !== undefined && isKey !== null) {
            queryParameters = queryParameters.set('is_key', <any>isKey);
        }
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (companyIds !== undefined && companyIds !== null) {
            queryParameters = queryParameters.set('company_ids', <any>companyIds);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserOverviewCollection>(`${this.basePath}/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of the companies a user is part of
     * 
     * @param companyid The ID of the company to invite the users to
     * @param companyUserInviteCollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteUsersToCompany(companyid: string, companyUserInviteCollection: CompanyUserInviteCollection, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inviteUsersToCompany(companyid: string, companyUserInviteCollection: CompanyUserInviteCollection, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inviteUsersToCompany(companyid: string, companyUserInviteCollection: CompanyUserInviteCollection, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inviteUsersToCompany(companyid: string, companyUserInviteCollection: CompanyUserInviteCollection, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling inviteUsersToCompany.');
        }

        if (companyUserInviteCollection === null || companyUserInviteCollection === undefined) {
            throw new Error('Required parameter companyUserInviteCollection was null or undefined when calling inviteUsersToCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/users/invite`,
            companyUserInviteCollection,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes the subscription with the specified ID number.
     * 
     * @param subscriptionid The ID of the subscription to remove
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeSubscription(subscriptionid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeSubscription(subscriptionid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeSubscription(subscriptionid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeSubscription(subscriptionid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subscriptionid === null || subscriptionid === undefined) {
            throw new Error('Required parameter subscriptionid was null or undefined when calling removeSubscription.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/subscriptions/${encodeURIComponent(String(subscriptionid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes a user from the company
     * 
     * @param userid The ID of the user to delete from the company
     * @param companyid The ID of the company to delete a user from
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeUserFromCompany(userid: string, companyid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeUserFromCompany(userid: string, companyid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeUserFromCompany(userid: string, companyid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeUserFromCompany(userid: string, companyid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling removeUserFromCompany.');
        }

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling removeUserFromCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/users/${encodeURIComponent(String(userid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marks notifications as \&quot;seen\&quot; by the authenticated user
     * 
     * @param seeNotificationsInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public seeNotifications(seeNotificationsInput: SeeNotificationsInput, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public seeNotifications(seeNotificationsInput: SeeNotificationsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public seeNotifications(seeNotificationsInput: SeeNotificationsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public seeNotifications(seeNotificationsInput: SeeNotificationsInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (seeNotificationsInput === null || seeNotificationsInput === undefined) {
            throw new Error('Required parameter seeNotificationsInput was null or undefined when calling seeNotifications.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/notifications/see`,
            seeNotificationsInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the settings for the specified API key
     * 
     * @param keyid The API key to update
     * @param companyid The company to add the API key to
     * @param aPIKeySettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAPIKey(keyid: string, companyid: string, aPIKeySettings: APIKeySettings, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAPIKey(keyid: string, companyid: string, aPIKeySettings: APIKeySettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAPIKey(keyid: string, companyid: string, aPIKeySettings: APIKeySettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAPIKey(keyid: string, companyid: string, aPIKeySettings: APIKeySettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyid === null || keyid === undefined) {
            throw new Error('Required parameter keyid was null or undefined when calling updateAPIKey.');
        }

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling updateAPIKey.');
        }

        if (aPIKeySettings === null || aPIKeySettings === undefined) {
            throw new Error('Required parameter aPIKeySettings was null or undefined when calling updateAPIKey.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/keys/${encodeURIComponent(String(keyid))}`,
            aPIKeySettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the specified subscription&#39;s settings
     * 
     * @param subscriptionid The ID of the subscription to update the settings for.
     * @param subscriptionSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubscriptionSettings(subscriptionid: string, subscriptionSettings: SubscriptionSettings, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSubscriptionSettings(subscriptionid: string, subscriptionSettings: SubscriptionSettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSubscriptionSettings(subscriptionid: string, subscriptionSettings: SubscriptionSettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSubscriptionSettings(subscriptionid: string, subscriptionSettings: SubscriptionSettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subscriptionid === null || subscriptionid === undefined) {
            throw new Error('Required parameter subscriptionid was null or undefined when calling updateSubscriptionSettings.');
        }

        if (subscriptionSettings === null || subscriptionSettings === undefined) {
            throw new Error('Required parameter subscriptionSettings was null or undefined when calling updateSubscriptionSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/subscriptions/${encodeURIComponent(String(subscriptionid))}`,
            subscriptionSettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the current user, returns the new user state
     * 
     * @param userUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(userUpdate: UserUpdate, observe?: 'body', reportProgress?: boolean): Observable<UserOverview>;
    public updateUser(userUpdate: UserUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserOverview>>;
    public updateUser(userUpdate: UserUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserOverview>>;
    public updateUser(userUpdate: UserUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userUpdate === null || userUpdate === undefined) {
            throw new Error('Required parameter userUpdate was null or undefined when calling updateUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserOverview>(`${this.basePath}/users/user`,
            userUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a user&#39;s association with a company
     * 
     * @param userid The ID of the user to change the company association for
     * @param companyid The ID of the company to change the user association for
     * @param userCompanyAssociationUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUserCompanyAssociation(userid: string, companyid: string, userCompanyAssociationUpdate: UserCompanyAssociationUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling updateUserCompanyAssociation.');
        }

        if (companyid === null || companyid === undefined) {
            throw new Error('Required parameter companyid was null or undefined when calling updateUserCompanyAssociation.');
        }

        if (userCompanyAssociationUpdate === null || userCompanyAssociationUpdate === undefined) {
            throw new Error('Required parameter userCompanyAssociationUpdate was null or undefined when calling updateUserCompanyAssociation.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/companies/${encodeURIComponent(String(companyid))}/users/${encodeURIComponent(String(userid))}`,
            userCompanyAssociationUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifies that the user who received the verification message would like to receive notifications by subscription
     * 
     * @param subscriptionid The ID of the subscription to remove
     * @param token The secret token sent to the user by email/sms
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifySubscription(subscriptionid: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public verifySubscription(subscriptionid: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public verifySubscription(subscriptionid: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public verifySubscription(subscriptionid: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subscriptionid === null || subscriptionid === undefined) {
            throw new Error('Required parameter subscriptionid was null or undefined when calling verifySubscription.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/subscriptions/${encodeURIComponent(String(subscriptionid))}/verification`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
