/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:28:25Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * An update to a company group settings. If parameters are not included, they won't be changed.
 */
export interface CompanyGroupSettingsUpdate { 
    /**
     * Updates the companies associations
     */
    companyAssociations?: Array<number>;
    /**
     * Updates the description of the company group
     */
    description?: string;
    /**
     * Updates the name of the company group.
     */
    name?: string;
}
