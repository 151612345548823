/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:23:40Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The types of notifications to subscribe to
 */
export interface SubscriptionSettingsNotificationTypes { 
    /**
     * Subscribe to notifications peripherals are disconnected from a SignalPort
     */
    disconnectedPeripheral?: boolean;
    /**
     * Subscribe to extreme overload notifications
     */
    extremeOverload?: boolean;
    /**
     * Subscribe to notifications when a SignalPort loses mains power.
     */
    lostMainsPower?: boolean;
    /**
     * Subscribe to low battery notifications
     */
    lowBattery?: boolean;
    /**
     * Subscribe to low impedance fault notifications
     */
    lowImpedanceFault?: boolean;
    /**
     * Subscribe to low signal notifications
     */
    lowSignal?: boolean;
    /**
     * Subscribe to notifications when a SignalPort misses a report.
     */
    missedReport?: boolean;
    /**
     * Subscribe to notifications when new versions of public APIs are released.
     */
    newApiRelease?: boolean;
    /**
     * Subscribe to notifications when new web app versions are released.
     */
    newAppRelease?: boolean;
    /**
     * Subscribe to notifications when new firmware versions are released.
     */
    newFirmwareRelease?: boolean;
    /**
     * Subscribe to overload notifications
     */
    overload?: boolean;
    /**
     * Subscribe to notifications power is restored to a SignalPort
     */
    restoredMainsPower?: boolean;
}
