/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T00:23:40Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SubscriptionSettings } from './subscriptionSettings';


export interface SubscriptionRequest { 
    /**
     * The endpoint to send notifications to. The format depends on the protocol. Email addresses should be in the format address@domain and phone numbers should be in the E.164 format \"+1XXX5550100\"
     */
    endpoint: string;
    /**
     * The type of endpoint to send the notifications to. Currently available options are \"sms\" and \"email\"
     */
    protocol: SubscriptionRequest.ProtocolEnum;
    settings: SubscriptionSettings;
}
export namespace SubscriptionRequest {
    export type ProtocolEnum = 'sms' | 'email';
    export const ProtocolEnum = {
        Sms: 'sms' as ProtocolEnum,
        Email: 'email' as ProtocolEnum
    };
}
