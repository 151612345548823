/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasurementFrame } from './measurementFrame';
import { PeripheralInfo } from './peripheralInfo';


/**
 * A collection of measurement frames
 */
export interface MeasurementFrameCollection { 
    /**
     * A collection of measurements
     */
    frames: Array<MeasurementFrame>;
    /**
     * A token that can included in a subsequent getMeasurementFrames query to get the next page of results
     */
    nextPage?: string;
    /**
     * Information about the peripherals that recorded the measurement frames mapped to their IDs
     */
    peripherals: { [key: string]: PeripheralInfo; };
}
