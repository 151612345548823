/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T23:28:47Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceFilters } from './deviceFilters';
import { DeviceSettingsUpdate } from './deviceSettingsUpdate';


/**
 * describes a conditional update request of device settings
 */
export interface DeviceSettingsConditionalUpdate { 
    /**
     * A set of filters to select which devices should receive the updates
     */
    filters?: DeviceFilters;
    /**
     * The settings to update the devices with
     */
    settings: DeviceSettingsUpdate;
}
